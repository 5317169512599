<script>
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import AntInput from '@/components/AntInput.vue';
import { mapGetters } from 'vuex';
import FileHandlerService from '@/services/file-handler';
import { phaseOptions } from '@/modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkb.utils';

export default {
  name: 'DhmeWkbFormsLibrary',
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      tableRecords: [],
      tableHeaders: [
        {
          text: 'Measurement',
          value: 'measurement_id',
          hasSlot: true,
        },
        {
          text: 'Leanforms form',
          value: 'lf_form',
        },
        {
          text: 'Phase',
          value: 'phase',
          hasSlot: true,
        },
        {
          text: 'Leanforms project',
          value: 'lf_project',
        },
        {
          text: 1,
          value: 'q1',
          hasSlot: true,
        },
        {
          text: 2,
          value: 'q2',
          hasSlot: true,
        },
        {
          text: 3,
          value: 'q3',
          hasSlot: true,
        },
        {
          text: 4,
          value: 'q4',
          hasSlot: true,
        },
        {
          text: 5,
          value: 'q5',
          hasSlot: true,
        },
        {
          text: 'BG',
          value: 'export_bg',
          hasSlot: true,
          width: '100px',
        },
        {
          text: 'Warranty',
          value: 'export_warranty',
          hasSlot: true,
          width: '100px',
        },
        {
          text: 'Contract',
          value: 'export_contract',
          hasSlot: true,
          width: '100px',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      wkbFormItem: {},
      wkbFormDialogDisplayed: false,
      wkbFormDeleteDialogDisplayed: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      measurementFilter: '',
    };
  },
  computed: {
    ...mapGetters([
      'wkbLibraryMeasurements',
      'wkbLibraryMeasurementsForms',
      'wkbLibraryTableStatus',
      'project',
    ]),

    formMeasurements() {
      return this.wkbLibraryMeasurements
        .filter((m) => m.type === 'leanform')
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    filteredForms() {
      if (this.measurementFilter) {
        return this.wkbLibraryMeasurementsForms.filter(
          (f) => f.measurement_id === this.measurementFilter
        );
      }

      return this.wkbLibraryMeasurementsForms;
    },
  },
  methods: {
    phaseOptions() {
      return phaseOptions;
    },
    closeWKBFormDialog() {
      this.wkbFormItem = Object.assign({}, {});
      this.wkbFormDialogDisplayed = false;
      this.wkbFormDeleteDialogDisplayed = false;
      this.$refs['create-wkb-form-form'].reset();
    },
    setupEdit(record) {
      this.wkbFormItem = Object.assign({}, record);
      this.wkbFormDialogDisplayed = true;
    },
    setupDelete(record) {
      this.wkbFormItem = Object.assign({}, record);
      this.wkbFormDeleteDialogDisplayed = true;
    },
    async saveOrUpdateWKBForm() {
      if (this.$refs['create-wkb-form-form'].validate()) {
        if (this.wkbFormItem.id) {
          // update item
          const recordId = this.wkbFormItem.id;
          delete this.wkbFormItem.id;
          if (this.wkbFormItem.default instanceof File) {
            await FileHandlerService.handleFile(this.wkbFormItem.default).then(
              (value) => {
                this.wkbFormItem.default = value;
              }
            );
          }
          this.$store
            .dispatch('updateWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_MEASUREMENT_FORMS',
              recordId,
              body: {
                record: this.wkbFormItem,
              },
            })
            .then(() => {
              this.closeWKBFormDialog();
            });
        } else {
          // create item
          if (this.wkbFormItem.default instanceof File) {
            await FileHandlerService.handleFile(this.wkbFormItem.default).then(
              (value) => {
                this.wkbFormItem.default = value;
              }
            );
          }
          this.$store
            .dispatch('createWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_MEASUREMENT_FORMS',
              body: {
                record: this.wkbFormItem,
              },
            })
            .then(() => {
              this.closeWKBFormDialog();
            });
        }
      }
    },
    deleteWkbFormRecord() {
      this.$store
        .dispatch('deleteWkbLibraryRecord', {
          table: 'CFFA_DHME_WKB_MEASUREMENT_FORMS',
          recordId: this.wkbFormItem.id,
        })
        .then(() => {
          this.closeWKBFormDialog();
        });
    },
  },
};
</script>

<template>
  <div class="ant-glass-background radius-0">
    <dynamic-data-table
      table-title=""
      :table-records="filteredForms"
      :table-headers="tableHeaders"
      :is-loading="wkbLibraryTableStatus === 'loading'"
      :can-edit="true"
      :can-delete="true"
      :project-id="project.id"
      @editItem="setupEdit"
      @deleteItem="setupDelete"
    >
      <template #table-buttons>
        <v-autocomplete
          v-model="measurementFilter"
          :items="formMeasurements"
          flat
          filled
          dense
          clearable
          hide-details
          style="max-width: 300px !important"
          placeholder="Measurement"
          item-text="name"
          item-value="id"
        />
      </template>
      <template #table-actions>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="ant-icon"
              v-on="on"
              @click="wkbFormDialogDisplayed = true"
            >
              mdi-plus
            </v-icon>
          </template>
          <span>Add form</span>
        </v-tooltip>
      </template>
      <template #item.measurement_id="{ item, rowId, value }">
        {{ formMeasurements.find((m) => m.id === value)?.name }}
      </template>
      <template #item.phase="{ item, rowId, value }">
        {{ value.substring(5) }}
      </template>
      <template #item.q1="{ item, rowId, value }">
        <div class="d-flex flex-column my-1">
          <v-chip x-small class="mb-1">Q: {{ item.q1 }}</v-chip>
          <v-chip x-small>A: {{ item.a1 }}</v-chip>
        </div>
      </template>
      <template #item.q2="{ item, rowId, value }">
        <div class="d-flex flex-column my-1">
          <v-chip x-small class="mb-1">Q: {{ item.q2 }}</v-chip>
          <v-chip x-small>A: {{ item.a2 }}</v-chip>
        </div>
      </template>
      <template #item.q3="{ item, rowId, value }">
        <div class="d-flex flex-column my-1">
          <v-chip x-small class="mb-1">Q: {{ item.q3 }}</v-chip>
          <v-chip x-small>A: {{ item.a3 }}</v-chip>
        </div>
      </template>
      <template #item.q4="{ item, rowId, value }">
        <div class="d-flex flex-column my-1">
          <v-chip x-small class="mb-1">Q: {{ item.q4 }}</v-chip>
          <v-chip x-small>A: {{ item.a4 }}</v-chip>
        </div>
      </template>
      <template #item.q5="{ item, rowId, value }">
        <div class="d-flex flex-column my-1">
          <v-chip x-small class="mb-1">Q: {{ item.q5 }}</v-chip>
          <v-chip x-small>A: {{ item.a5 }}</v-chip>
        </div>
      </template>
      <template #item.export_bg="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
      <template #item.export_warranty="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
      <template #item.export_contract="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
    </dynamic-data-table>
    <v-dialog
      key="wkb-form"
      v-model="wkbFormDialogDisplayed"
      max-width="600"
      @click:outside="closeWKBFormDialog"
      @keydown.esc="closeWKBFormDialog"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase headline">
          {{ wkbFormItem.id ? 'Update' : 'Add' }} Form
        </v-card-title>
        <v-divider />
        <div class="px-10 pb-5">
          <v-form ref="create-wkb-form-form" @submit.prevent>
            <ant-input label="Measurement">
              <template #input-field>
                <v-autocomplete
                  v-model="wkbFormItem.measurement_id"
                  :items="formMeasurements"
                  filled
                  placeholder="Measurement"
                  :rules="[rules.required]"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  hide-details
                  dense
                  item-value="id"
                  item-text="name"
                />
              </template>
            </ant-input>

            <div class="d-flex">
              <ant-input label="Leanforms form" class="mr-5" style="flex: 1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.lf_form"
                    filled
                    placeholder="Form ID"
                    :rules="[rules.required]"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>

              <ant-input label="Leanforms project" style="flex: 2" is-optional>
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.lf_project"
                    filled
                    placeholder="Project"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <ant-input label="Phase">
              <template #input-field>
                <v-autocomplete
                  v-model="wkbFormItem.phase"
                  :items="phaseOptions()"
                  filled
                  placeholder="Phase"
                  :rules="[rules.required]"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  hide-details
                  dense
                  item-value="id"
                  item-text="name"
                />
              </template>
            </ant-input>

            <div class="d-flex">
              <ant-input label="Q1" is-optional class="mr-5 flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.q1"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="A1" is-optional class="flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.a1"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <div class="d-flex">
              <ant-input label="Q2" is-optional class="mr-5 flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.q2"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="A2" is-optional class="flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.a2"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <div class="d-flex">
              <ant-input label="Q3" is-optional class="mr-5 flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.q3"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="A3" is-optional class="flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.a3"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <div class="d-flex">
              <ant-input label="Q4" is-optional class="mr-5 flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.q4"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="A4" is-optional class="flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.a4"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <div class="d-flex">
              <ant-input label="Q5" is-optional class="mr-5 flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.q5"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="A5" is-optional class="flex-1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbFormItem.a5"
                    filled
                    placeholder="Value"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <div class="d-flex justify-center">
              <ant-input label="Export BG" is-optional class="flex-1">
                <template #input-field>
                  <v-checkbox
                    v-model="wkbFormItem.export_bg"
                    :value="false"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
              <ant-input
                label="Export Warranty"
                is-optional
                class="mx-5 flex-1"
              >
                <template #input-field>
                  <v-checkbox
                    v-model="wkbFormItem.export_warranty"
                    :value="false"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="Export Contract" is-optional class="flex-1">
                <template #input-field>
                  <v-checkbox
                    v-model="wkbFormItem.export_contract"
                    :value="false"
                    hide-details
                    dense
                  />
                </template>
              </ant-input>
            </div>
          </v-form>
        </div>
        <v-card-actions class="ant-border-top ant-dialog-actions-bg">
          <v-spacer />
          <v-btn
            text
            small
            color="error"
            class="mx-2"
            :disabled="wkbLibraryTableStatus === 'loading'"
            @click="closeWKBFormDialog"
            >cancel
          </v-btn>
          <v-btn
            color="primary"
            small
            :loading="wkbLibraryTableStatus === 'loading'"
            :disabled="wkbLibraryTableStatus === 'loading'"
            @click="saveOrUpdateWKBForm"
            >{{ wkbFormItem.id ? 'update' : 'create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :dialog="wkbFormDeleteDialogDisplayed"
      title="Are you sure you want to delete this record?"
      @closeDialog="closeWKBFormDialog"
      @deleteAction="deleteWkbFormRecord"
    />
  </div>
</template>

<style scoped lang="scss"></style>
